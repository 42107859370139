<script>
import { nextTick } from 'vue';
import { mapActions, mapState } from 'pinia';

import useModalStore from 'learning/common/store-modules/modal.ts';

import KogTrapFocus from 'sharedApp/directives/kog-trap-focus.js';
import isMobile from 'sharedApp/utils/detect-mobile.js';
import { getFirstFocusableElement } from 'sharedApp/utils/dom-utils.js';

export default {
  name: 'ModalsContainer',
  directives: {
    KogTrapFocus,
  },
  data() {
    return {
      previouslyFocused: null,
    };
  },
  computed: {
    ...mapState(useModalStore, {
      modalProps: state => state.props,
      modalOptions: state => state.options,
      displayingModal: state => state.displayingModal,
      modalComponent: state => state.component,
    }),
    modalPropsWithClose() {
      return {
        ...this.modalProps,
        closeModal: this.removeModal,
      };
    },
  },
  watch: {
    displayingModal(newVal) {
      if (newVal) {
        this.show();
      } else {
        this.hide();
      }
    },
  },
  methods: {
    ...mapActions(useModalStore, ['removeModal']),
    show() {
      nextTick().then(() => {
        // Wait next tick to allow Vue to insert the elements in the DOM
        this.previouslyFocused = document.activeElement;
        this.focusFirstElement();
        window.addEventListener('keydown', this.keyEvent);
        const [body] = document.getElementsByTagName('body');
        body.classList.add('kogmodal-open');
        if (this.modalOptions.zIndex) {
          this.$refs.modalContainer.style.zIndex = this.modalOptions.zIndex;
        }
      });
    },
    hide(event) {
      if (event) {
        event.stopPropagation();
      }
      window.removeEventListener('mousewheel', this.scrollEvent);
      window.removeEventListener('keydown', this.keyEvent);
      const [body] = document.getElementsByTagName('body');
      body.classList.remove('kogmodal-open');
      if (this.previouslyFocused) {
        this.previouslyFocused.focus();
      }
    },
    focusFirstElement() {
      if (this.$refs.modal) {
        const firstFocusableElement = getFirstFocusableElement(this.$refs.modalVisibleArea);
        if (firstFocusableElement) {
          firstFocusableElement.focus();
        }
      }
    },
    keyEvent(event) {
      if (this.modalOptions.closeOnEsc && event.keyCode === 27) {
        this.removeModal();
      }
    },
    dontPropagate(event) {
      event.stopPropagation();
    },
    clickOutside() {
      if (this.modalOptions.closeOnClickOutside) {
        this.removeModal();
      }
    },
    mouseUpHandler() {
      // This handler is intended for closing modal on mobile devices.
      // It is important to skip it in desktop browsers otherwise it can cause closing
      // modal when any other component which does not handle mouseup event and
      // is closed outside of modal.
      if (isMobile() && this.modalOptions.closeOnClickOutside) {
        this.removeModal();
      }
    },
  },
};
</script>

<template>
  <transition name="slide">
    <div
      v-if="displayingModal && modalComponent"
      ref="modalContainer"
      class="ModalsContainer"
      :class="{
        'u-noScroll': modalOptions.preventScroll,
      }"
      @click="clickOutside"
      @mouseup="mouseUpHandler"
    >
      <div
        ref="modalVisibleArea"
        v-kog-trap-focus
        :class="{
          'ModalsContainer__visibleArea-top': modalOptions.attachModalToTop,
          'ModalsContainer__visibleArea-center': !modalOptions.attachModalToTop,
        }"
        @click="dontPropagate"
        @mouseup="dontPropagate"
      >
        <component
          v-bind="modalPropsWithClose"
          :is="modalComponent"
          ref="modal"
        />
      </div>
    </div>
  </transition>
</template>

<style scoped>
.ModalsContainer {
  position: fixed;
  z-index: 1055; /* bootstrap modal is at 1050, ensures that this shows on top */
  top: 0;
  left: 0;

  overflow-y: auto;
  display: flex;
  justify-content: center;

  width: 100%;
  height: 100vh;
  height: 100svh !important;

  background-color: color-mod(var(--kogPlatformGray018) alpha(50%));
}

.ModalsContainer__visibleArea-center {
  position: relative;
  margin: auto;
}

.ModalsContainer__visibleArea-top {
  position: absolute;
  margin: 0 auto;
}

.slide-enter-active,
.slide-leave-active {
  transition: forwards ease-out 0.15s;
  transition-property: transform, opacity;
}

.slide-enter-to,
.slide-leave-from {
  transform: translate3d(0, 0, 0);
  opacity: 1;
  background-color: color-mod(var(--kogPlatformGray018) alpha(50%));
}

.slide-enter-from,
.slide-leave-to {
  transform: translate3d(0, 20px, 0);
  opacity: 0;
  background-color: color-mod(var(--kogPlatformGray018) alpha(0%));
}

.u-noScroll {
  overflow: hidden !important;
}
</style>

<style>
.kogmodal-open {
  overflow: hidden;
}
</style>
