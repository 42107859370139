import { compile } from 'path-to-regexp';

import axios from 'sharedApp/vue-utils/kog-axios.ts';

export const API_URLS = {
  'user-details': compile('/api/accounts/users/:userId/'),
  'user-profile': '/api/accounts/user_profile/',
  'user-search': '/api/accounts/users/search',
  'user-list': '/api/accounts/users',
};

export async function searchUsers(searchQuery, page = 1, pageSize = 15) {
  const data = { search: searchQuery };
  const queryParams = new URLSearchParams({ page, page_size: pageSize });
  const result = await axios.post(API_URLS['user-search'], data, { params: queryParams });
  return result.data;
}

export async function fetchUserDetails(userId) {
  const url = API_URLS['user-details']({ userId });
  const response = await axios.get(url);
  return response.data;
}

export async function fetchUsersList(userIds) {
  if (userIds.length === 0) {
    return [];
  }
  const url = API_URLS['user-list'];
  const userIdsSet = new Set(userIds);
  const params = { all: 'True', id__in: [...userIdsSet].join(',') };
  const response = await axios.get(url, { params });
  return response.data.results;
}
