import { getExerciseQuestions } from '@apis/questions.js';

const defaultState = {
  availableQuestions: [],
};

const actions = {
  async fetchAvailableQuestions({ commit }, { subjectNodeId, showAnswers = false, shuffleSeed }) {
    if (!subjectNodeId) {
      throw new Error('missing subject node id');
    }
    const questions = await getExerciseQuestions({
      subjectNodeIds: [subjectNodeId],
      showAnswers,
      detail: true,
      shuffleSeed,
    });
    commit('setAvailableQuestions', questions);
  },
};

const getters = {
  getQuestionByUuid(state) {
    return uuid => state.availableQuestions.find(question => question.uuid === uuid);
  },
  getMaxTotalForAllQuestions(state) {
    const total = state.availableQuestions.reduce((totalCount, question) => {
      const points = question.rubric_items?.map(rubric => rubric.points) || [];
      return totalCount + Math.max(0, ...points);
    }, 0);
    return total;
  },
};

const mutations = {
  setAvailableQuestions(state, questions) {
    state.availableQuestions = questions;
  },
};

export const QUESTION_ACTIVITY_MODULE = 'questionActivityModule';
export default {
  namespaced: true,
  state: defaultState,
  actions,
  getters,
  mutations,
};
