/* eslint-disable no-underscore-dangle */
module.exports = (numberSymbols = []) => {
  const DEFAULT_NUMBER_SYMBOLS = ['#1', '#2', '#3', '#4', '#5', '#6'];
  const NUMBER_SYMBOLS = numberSymbols.length > 0 ? numberSymbols : DEFAULT_NUMBER_SYMBOLS;

  function setOriginalNumberOnChoices(choices) {
    choices.forEach((choice, index) => {
      const number = index + 1;
      if (choice._originalNumber !== undefined) {
        return;
      }
      choice._originalNumber = number; // eslint-disable-line no-param-reassign
    });
  }

  /**
   * Fisher–Yates shuffle algorithm
   */
  function shuffleArray(array) {
    let m = array.length;
    let i;

    // While there remain elements to shuffle
    while (m) {
      // Pick a remaining element
      i = Math.floor(Math.random() * m);
      m -= 1;

      // And swap it with the current element.
      [array[m], array[i]] = [array[i], array[m]]; // eslint-disable-line no-param-reassign
    }

    return array;
  }

  function createReplaceMappings(choices) {
    const replaceMappings = {};
    choices.forEach((choice, index) => {
      const number = choice._originalNumber;
      const markupToReplace = `@${number}`;
      const symbolToReplaceWith = NUMBER_SYMBOLS[index];
      const htmlToReplaceWith = `<span class="mcq-choice-ref">${symbolToReplaceWith}</span>`;
      replaceMappings[markupToReplace] = htmlToReplaceWith;
    });
    return replaceMappings;
  }

  function escapeRegExp(string) {
    return string.replace(/([.*+?^=!:${}()|[\]/\\])/g, '\\$1');
  }

  function replaceAll(string, find, replace) {
    return string.replace(new RegExp(escapeRegExp(find), 'g'), replace);
  }

  function replaceReferences(choice, replaceMappings) {
    if (choice.answer_explanation_html !== undefined) {
      let replacedHtml = choice.answer_explanation_html;
      let htmlToReplaceWith;
      Object.keys(replaceMappings).forEach(markupToReplace => {
        htmlToReplaceWith = replaceMappings[markupToReplace];
        replacedHtml = replaceAll(replacedHtml, markupToReplace, htmlToReplaceWith);
      });
      choice.answer_explanation_html = replacedHtml; // eslint-disable-line no-param-reassign
    }
  }

  function setNumberSymbols(choice, choiceKey) {
    choice._numberSymbol = NUMBER_SYMBOLS[choiceKey]; // eslint-disable-line no-param-reassign
  }

  function replaceReferencesToNumberingInExplanations(choices) {
    // Create reference mappings
    const replaceMappings = createReplaceMappings(choices);
    choices.forEach((choice, index) => {
      replaceReferences(choice, replaceMappings);
      setNumberSymbols(choice, index);
    });
  }

  return {
    updateMultipleChoiceQuestionWithNumbering(mcq, shuffle, choices) {
      const mcqChoices = choices || mcq.multiplechoicequestionchoice_set || mcq.context?.choices;
      if (!mcqChoices) {
        return;
      }
      setOriginalNumberOnChoices(mcqChoices);
      if (shuffle && !mcq._shuffled) {
        shuffleArray(mcqChoices);
        mcq._shuffled = true; // eslint-disable-line no-param-reassign
      }
      replaceReferencesToNumberingInExplanations(mcqChoices);
    },
    updateSeparateMultipleChoiceQuestionChoiceWithNumbering(mcq, separateChoice, choices) {
      const mcqChoices = choices || mcq.multiplechoicequestionchoice_set || mcq.context?.choices;
      setOriginalNumberOnChoices(mcqChoices);
      const mappings = createReplaceMappings(mcqChoices);
      const keyOfSeparateChoice = mcqChoices.findIndex(m => m.id === separateChoice.id);

      replaceReferences(separateChoice, mappings);
      setNumberSymbols(separateChoice, keyOfSeparateChoice);
    },
    setNumberSymbols(mcq) {
      mcq.multiplechoicequestionchoice_set.forEach((choice, index) => {
        setNumberSymbols(choice, index);
      });
    },
    setNumberSymbolsForSingleChoice(mcq, singleChoice) {
      for (let i = 0; i < mcq.multiplechoicequestionchoice_set.length; i += 1) {
        // Check for object equality or id equality, to cover both cases where
        // the objects are the same but no id's are available (new question)
        // and cases where the objects are not same but have ids (correct choice
        // for an existing question comming from the server).
        if (
          mcq.multiplechoicequestionchoice_set[i] === singleChoice ||
          mcq.multiplechoicequestionchoice_set[i].id === singleChoice.id
        ) {
          setNumberSymbols(singleChoice, i);
          break;
        }
      }
    },
  };
};
