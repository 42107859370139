<script>
export const TRANSITIONS = {
  DEFAULT: 'no-transition',
  COVER_FROM_RIGHT: 'cover-from-right',
  UNCOVER_TO_RIGHT: 'uncover-to-right',
};

export default {
  name: 'PageTransition',
  emits: ['before-navigation', 'after-leave-transition'],
  data() {
    return {
      transitionName: TRANSITIONS.DEFAULT,
    };
  },
  created() {
    this.$router.beforeEach((to, from, next) => {
      this.transitionName = this.getTransitionName(to, from);
      this.$emit('before-navigation', this.transitionName);
      next();
    });
  },
  methods: {
    onAfterLeave() {
      this.$emit('after-leave-transition');
    },
    getTransitionName(to, from) {
      if (from.name === to.name) {
        return TRANSITIONS.DEFAULT;
      }

      const toEnterTransition = to.meta.enterTransition;
      const fromLeaveTransition = from.meta.leaveTransition;
      const isTransitionOnBothPages = toEnterTransition && fromLeaveTransition;

      if (!isTransitionOnBothPages) {
        return toEnterTransition || fromLeaveTransition || TRANSITIONS.DEFAULT;
      }
      if (to.meta.pageOverlaySequence > from.meta.pageOverlaySequence) {
        return toEnterTransition;
      }
      return fromLeaveTransition;
    },
  },
};
</script>

<template>
  <transition
    :name="transitionName"
    @after-leave="onAfterLeave"
  >
    <slot />
  </transition>
</template>

<style scoped>
.cover-from-right-enter-from,
.cover-from-right-leave-to {
  transform: translateX(20%) !important;
  opacity: 0 !important;
}

.cover-from-right-enter-active {
  position: absolute;
  z-index: 502;
  top: 0;
  transform: translateX(0);

  width: 100%;

  opacity: 1;

  transition-delay: 0.1s;
  transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
  transition-duration: 0.4s;
  transition-property: transform, opacity;
}

.cover-from-right-leave-active {
  opacity: 0;
  transition-timing-function: cubic-bezier(0, 0, 1, 1);
  transition-duration: 0.3s;
  transition-property: opacity;
}

.uncover-to-right-leave-active {
  position: absolute;
  z-index: 502;
  top: 0;
  transform: translateX(25%);

  width: 100%;

  opacity: 0;

  transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
  transition-duration: 0.5s;
  transition-property: transform, opacity;
}

.uncover-to-right-enter-from,
.uncover-to-right-leave-to {
  opacity: 0 !important;
}

.uncover-to-right-enter-active {
  opacity: 1;

  transition-delay: 0.2s;
  transition-timing-function: cubic-bezier(0, 0, 1, 1);
  transition-duration: 0.3s;
  transition-property: opacity;
}

.no-transition-enter-to,
.no-transition-leave-to,
.no-transition-enter-active,
.no-transition-leave-active {
  transition-duration: 0s !important;
}
</style>
