import { SubjectNodesService } from '@apis/generated/services.ts';

const namespaced = true;

const mutations = {
  setStudentFeedbackItems(state, { studentUserId, feedbackItem }) {
    state.feedbackItems[studentUserId] = [feedbackItem];
  },
  setClassFeedbackItems(state, feedbackItems) {
    const initialState = feedbackItems.reduce(
      (dict, feedbackItem) => ({
        ...dict,
        [feedbackItem.student_user_id]: [],
      }),
      {},
    );

    const indexByStudentUserId = (dict, feedbackItem) => {
      const studentFeedback = [...dict[feedbackItem.student_user_id], feedbackItem];
      return {
        ...dict,
        [feedbackItem.student_user_id]: studentFeedback,
      };
    };
    state.feedbackItems = feedbackItems.reduce(indexByStudentUserId, initialState);
  },
};

const actions = {
  async fetchFeedbackItems({ commit }, { subjectNodeId, questionId, subjectClassId }) {
    const feedbackItems = await SubjectNodesService.subjectNodesQuestionsFeedbackItemsList({
      subjectnodeId: subjectNodeId,
      questionId,
      subjectClassId,
    });
    commit('setClassFeedbackItems', feedbackItems);
  },
  async createFeedbackItem(
    { commit },
    { subjectClassId, subjectNodeId, questionId, studentId, text, operation },
  ) {
    const feedbackItem = await SubjectNodesService.subjectNodesQuestionsFeedbackItemsCreate({
      subjectnodeId: subjectNodeId,
      questionId,
      requestBody: {
        text,
        operation,
        student_id: studentId,
        subject_class_id: subjectClassId,
      },
    });
    commit('setStudentFeedbackItems', {
      studentUserId: feedbackItem.student_user_id,
      feedbackItem,
    });
  },
  async updateFeedbackItem(
    { commit },
    { feedbackItemId, subjectClassId, subjectNodeId, questionId, studentId, text, operation },
  ) {
    const feedbackItem = await SubjectNodesService.subjectNodesQuestionsFeedbackItemsUpdate({
      subjectnodeId: subjectNodeId,
      questionId,
      feedbackItemId,
      requestBody: {
        text,
        operation,
        student_id: studentId,
        subject_class_id: subjectClassId,
      },
    });

    commit('setStudentFeedbackItems', {
      studentUserId: feedbackItem.student_user_id,
      feedbackItem,
    });
  },
  clearFeedbackItems({ commit }) {
    commit('setClassFeedbackItems', []);
  },
};

const state = {
  feedbackItems: {},
};

export default {
  namespaced,
  actions,
  mutations,
  state,
};
