/* global MathJax:false */
import * as Sentry from '@sentry/vue';

function waitForMathJax(retryTime = 0) {
  return new Promise(resolve => {
    if (
      window.MathJax &&
      window.MathJax.typesetPromise &&
      typeof window.MathJax.typesetPromise === 'function' &&
      window.MathJax.typesetClear &&
      typeof window.MathJax.typesetClear === 'function'
    ) {
      resolve();
    } else {
      setTimeout(() => {
        waitForMathJax(50).then(resolve);
      }, retryTime);
    }
  });
}

async function cleanUpMathJax(el) {
  await waitForMathJax();
  window.MathJax.typesetClear([el]);
}

function appendMathjaxA11yControlsLabel() {
  document
    .querySelectorAll('mjx-container[role="application"]:not([data-mathjax-controls-enhanced])')
    .forEach(container => {
      const currentLabel = container.getAttribute('aria-label');
      container.setAttribute(
        'aria-label',
        `${currentLabel}, Use shift + space to explore the formula then arrow keys to navigate`,
      );
      container.setAttribute('data-mathjax-controls-enhanced', 'true');
    });
}

async function addMathJaxTo(el, binding) {
  const config = binding.value || {};
  const callback = config.doneCallback || (() => {});

  await waitForMathJax();

  window.MathJax.typesetClear([el]);
  window.MathJax.typesetPromise([el])
    .then(() => {
      appendMathjaxA11yControlsLabel();
      callback();
    })
    .catch(e => {
      Sentry.captureException(`MathJax error: ${e}`);
    });
}

export default {
  beforeMount: addMathJaxTo,
  beforeUpdate: cleanUpMathJax,
  updated: addMathJaxTo,
  beforeUnmount: cleanUpMathJax,
};
