import { ActivitySheetsService } from '@apis/generated/services.ts';

export default {
  createInstance({ featureTitle }) {
    return ActivitySheetsService.activitySheetsCreate({
      requestBody: { title: featureTitle },
    });
  },
  removeInstance(activitySheetId) {
    return ActivitySheetsService.activitySheetsDestroy({ id: activitySheetId });
  },
  fetchInstance(activitySheetId) {
    return ActivitySheetsService.activitySheetsRetrieve({ activitySheetId });
  },
};
