import * as Sentry from '@sentry/vue';
import { cloneDeep, xor } from 'lodash';

import { SubjectNodesService } from '@apis/generated';
import { getSectionQuestions } from '@apis/questions';

import multipleChoiceQuestionNumberingService from 'sharedApp/services/questions/multipleChoiceQuestionNumbering/multiple-choice-question-numbering-service.js';
import {
  isMCQ,
  isSTQ,
} from 'sharedApp/services/questions/questionUtilityService/question-utility-service.js';
import { fetchReadingAssignmentsForSection } from 'studyApp/api/assignment.js';
import { addAnswer, fetchSectionNodes } from 'studyApp/api/subject.js';

const mcqNumberingService = multipleChoiceQuestionNumberingService();

export default {
  namespaced: true,
  state: {
    sections: {},
    sectionCheckpointOccasionId: null,
    sectionQuestionPracticeItems: [],
    sectionQuestionsWithSolutionById: {},
    sectionQuestionIdsBySubjectNodeId: {},
    currentPracticeItemIdx: -1,
    ongoingReadingAssignmentOccasions: [],
    sectionQuestionCountByNodeId: {},
    bookRailSliders: {
      toc: false,
      assignment: false,
      unitBinder: false,
      glossary: false,
      notebook: false,
    },
    immersiveReaderContent: '',
  },
  mutations: {
    setRailSliderState(state, { sliderKey, flag }) {
      state.bookRailSliders[sliderKey] = flag;
    },
    setSections(state, sections) {
      state.sections = sections;
    },
    setSectionQuestionPracticeItems(state, sectionQuestionPracticeItems) {
      state.sectionQuestionPracticeItems = sectionQuestionPracticeItems.map(practiceItem => {
        if (isMCQ(practiceItem.question)) {
          mcqNumberingService.updateMultipleChoiceQuestionWithNumbering(
            practiceItem.question,
            true,
          );
        }

        return practiceItem;
      });
    },
    updateSectionQuestionsWithSolutionById(state, sectionQuestionsWithSolutionById) {
      state.sectionQuestionsWithSolutionById = {
        ...state.sectionQuestionsWithSolutionById,
        ...sectionQuestionsWithSolutionById,
      };
    },
    updateSectionQuestionIdsBySubjectNodeId(state, sectionQuestionIdsBySubjectNodeId) {
      state.sectionQuestionIdsBySubjectNodeId = {
        ...state.sectionQuestionIdsBySubjectNodeId,
        ...sectionQuestionIdsBySubjectNodeId,
      };
    },
    setSectionCheckpointOccasionId(state, occasionId) {
      state.sectionCheckpointOccasionId = occasionId;
    },
    setSectionQuestionCountByNodeId(state, { subjectNodeId, questionCount }) {
      state.sectionQuestionCountByNodeId = {
        ...state.sectionQuestionCountByNodeId,
        [subjectNodeId]: questionCount,
      };
    },
    goToNextQuestion(state) {
      state.currentPracticeItemIdx += 1;
    },
    setCurrentAnswer(state, answer) {
      if (state.currentPracticeItemIdx >= 0) {
        state.sectionQuestionPracticeItems = state.sectionQuestionPracticeItems.map(
          (practiceItem, index) => {
            if (index !== state.currentPracticeItemIdx) return practiceItem;

            const { answerToSubmit, question } = practiceItem;

            let newAnswer = answer;

            if (isMCQ(question)) {
              if (question.is_multi_select) {
                newAnswer = xor(answerToSubmit, [answer]);
              } else {
                newAnswer = [answer];
              }
            }

            return {
              ...practiceItem,
              user_answer: { answer: newAnswer },
              answerToSubmit: newAnswer,
            };
          },
        );
      }
    },
    resetCurrentQuestion(state) {
      state.currentPracticeItemIdx = -1;
      state.sectionQuestionPracticeItems = [];
    },
    prepPracticeItemForSubmission(state) {
      state.sectionQuestionPracticeItems = state.sectionQuestionPracticeItems.map(
        (practiceItem, index) => {
          if (index !== state.currentPracticeItemIdx) return practiceItem;

          return {
            ...practiceItem,
            last_submitted_answer: practiceItem.user_answer.answer,
            user_answer: {
              ...practiceItem.user_answer,
              is_correct: undefined,
            },
          };
        },
      );
    },
    setPracticeItemSubmissionResult(state, answerResult) {
      state.sectionQuestionPracticeItems = state.sectionQuestionPracticeItems.map(
        (practiceItem, index) => {
          if (index !== state.currentPracticeItemIdx) return practiceItem;

          let { solution } = answerResult;
          const { user_answer: userAnswer } = answerResult;

          if (isSTQ(practiceItem.question)) {
            userAnswer.answer = userAnswer.user_text;
          }

          if (isMCQ(practiceItem.question)) {
            const clonedQuestion = cloneDeep(practiceItem.question);
            const clonedSolution = cloneDeep(solution);

            const mutateSolutionNumbering = questionSolution => {
              mcqNumberingService.updateSeparateMultipleChoiceQuestionChoiceWithNumbering(
                clonedQuestion,
                questionSolution,
              );
            };

            if (Array.isArray(clonedSolution)) {
              clonedSolution.forEach(mutateSolutionNumbering);
            } else {
              mutateSolutionNumbering(clonedSolution);
            }

            solution = clonedSolution;
          }

          return {
            ...practiceItem,
            solution,
            user_answer: userAnswer,
          };
        },
      );
    },
    setOngoingReadingAssignmentOccasions(state, occasions) {
      state.ongoingReadingAssignmentOccasions = occasions;
    },
    setImmersiveReaderContent(state, immersiveReaderContent) {
      state.immersiveReaderContent = immersiveReaderContent;
    },
    resetImmersiveReaderContent(state) {
      state.immersiveReaderContent = '';
    },
  },
  getters: {
    isBookRailSliderOpen(state) {
      return Object.values(state.bookRailSliders).includes(true);
    },
    sectionNodes(state) {
      if (!state.sections.sectionnodes) {
        return [];
      }
      return state.sections.sectionnodes;
    },
    currentNodeParent(state) {
      return state.sections.subjectnode;
    },
    currentPracticeItem(state) {
      if (state.currentPracticeItemIdx < 0) {
        return null;
      }
      return state.sectionQuestionPracticeItems[state.currentPracticeItemIdx];
    },
    findSectionNodeById(_, getters) {
      return sectionNodeId => {
        if (!sectionNodeId) {
          return null;
        }
        return getters.sectionNodes.find(sn => sn.id.toString() === sectionNodeId.toString());
      };
    },
  },
  actions: {
    async fetchSections(
      { commit },
      { subjectSlug, topicSlug, subtopicSlug, sectionSlug, subsectionSlug },
    ) {
      try {
        let actualSectionSlug = null;
        if (subsectionSlug) {
          actualSectionSlug = sectionSlug;
        }
        const data = await fetchSectionNodes(
          subjectSlug,
          topicSlug,
          subtopicSlug,
          actualSectionSlug,
        );
        commit('setSections', data);
      } catch (error) {
        Sentry.captureMessage(`fetchSectionNodes: ${error}`);
      }
    },
    async fetchSectionQuestionCount({ commit }, { subjectNodeId, level }) {
      commit('resetCurrentQuestion');
      const response = await getSectionQuestions(subjectNodeId, {
        additionalParams: {
          showAnswers: false,
          all: false,
          active: true,
          level,
        },
      });
      commit('setSectionQuestionCountByNodeId', { subjectNodeId, questionCount: response.count });
    },
    async fetchSectionQuestionsWithSolution({ commit, state }, { subjectNodeIds }) {
      const subjectNodeIdsToFetch = subjectNodeIds.filter(
        nodeId => state.sectionQuestionIdsBySubjectNodeId[nodeId] === undefined,
      );

      if (subjectNodeIdsToFetch.length === 0) {
        return;
      }

      const sectionQuestionsWithSolutionById = {};
      const sectionQuestionIdsBySubjectNodeId = {};
      const questions = await getSectionQuestions(subjectNodeIdsToFetch, {
        additionalParams: {
          showAnswers: 'True',
        },
      });
      const subjectNodeIdsWithoutQuestions = [...subjectNodeIdsToFetch];

      questions.forEach(question => {
        sectionQuestionsWithSolutionById[question.id] = question;
        question.subjectnodes
          .filter(nodeId => subjectNodeIdsToFetch.includes(nodeId))
          .forEach(nodeId => {
            const index = subjectNodeIdsWithoutQuestions.indexOf(nodeId);
            if (index > -1) {
              subjectNodeIdsWithoutQuestions.splice(index, 1);
            }

            if (!sectionQuestionIdsBySubjectNodeId[nodeId]) {
              sectionQuestionIdsBySubjectNodeId[nodeId] = [];
            }
            sectionQuestionIdsBySubjectNodeId[nodeId].push(question.id);
          });
      });

      subjectNodeIdsWithoutQuestions.forEach(nodeId => {
        sectionQuestionIdsBySubjectNodeId[nodeId] = [];
      });
      commit('updateSectionQuestionsWithSolutionById', sectionQuestionsWithSolutionById);
      commit('updateSectionQuestionIdsBySubjectNodeId', sectionQuestionIdsBySubjectNodeId);
    },
    async submitCurrentAnswer({ getters, commit }, sectionNodeId) {
      const practiceItem = getters.currentPracticeItem;
      commit('prepPracticeItemForSubmission');
      const response = await addAnswer(
        sectionNodeId,
        practiceItem.id,
        practiceItem.question.id,
        practiceItem.user_answer.answer,
      );
      commit('setPracticeItemSubmissionResult', response[0]);
    },
    async fetchReadingAssignmentForSection({ commit }, sectionNodeId) {
      try {
        const data = await fetchReadingAssignmentsForSection(sectionNodeId);
        commit('setOngoingReadingAssignmentOccasions', data.results);
      } catch (error) {
        Sentry.captureMessage(`fetchReadingAssignmentForSection: ${error}`);
      }
    },
    async startSectionQuestion({ commit }, sectionNodeId) {
      const sectionCheckpointOccasion =
        await SubjectNodesService.subjectNodesSectionCheckpointOccasionsCreate({
          subjectnodeId: sectionNodeId,
          requestBody: {},
        });
      const {
        id: occasionId,
        practice_items: practiceItems,
        questions,
      } = sectionCheckpointOccasion;
      const practiceItemsWithQuestions = practiceItems.map((item, index) => ({
        ...item,
        question: questions[index],
      }));
      commit('setSectionCheckpointOccasionId', occasionId);
      commit('setSectionQuestionPracticeItems', practiceItemsWithQuestions);
    },
    closeRailSlider({ state, commit }) {
      const keys = Object.keys(state.bookRailSliders);
      keys.forEach(key => {
        commit('setRailSliderState', { sliderKey: key, flag: false });
      });
    },
    async openRailSlider({ commit, dispatch }, sliderKey) {
      await dispatch('closeRailSlider');
      commit('setRailSliderState', { sliderKey, flag: true });
    },
  },
};
