import { capitalize } from 'vue';
import { compile } from 'path-to-regexp';
import qs from 'qs';

import { QUESTION_TYPES } from 'sharedApp/services/questions/questionUtilityService/question-utility-service.js';
import axios from 'sharedApp/vue-utils/kog-axios.ts';

const SCHOOL_STAFF_BASE_URL = '/api/schoolstaff/';
const SUBJECT_QUESTION_BASE_URL = `${SCHOOL_STAFF_BASE_URL}question/:subjectId/`;
const SCHOOLSTAFF_API_URLS = {
  'mcq-detail': compile(`${SUBJECT_QUESTION_BASE_URL}mcq/:questionId/`),
  'mcq-list': compile(`${SUBJECT_QUESTION_BASE_URL}mcq/`),
  'stq-detail': compile(`${SUBJECT_QUESTION_BASE_URL}stq/:questionId/`),
  'stq-list': compile(`${SUBJECT_QUESTION_BASE_URL}stq/`),
  'ltq-detail': compile(`${SUBJECT_QUESTION_BASE_URL}ltq/:questionId/`),
  'ltq-list': compile(`${SUBJECT_QUESTION_BASE_URL}ltq/`),
  'subject-exam-style-questions': compile(
    `${SCHOOL_STAFF_BASE_URL}subjects/:subjectId/exam_style_questions_by_topic/`,
  ),
  'exam-style-questions-list-view': compile(
    `${SCHOOL_STAFF_BASE_URL}subjects/:subjectId/exam_style_questions/`,
  ),
};
const QUESTION_LIST_URL = '/api/questions/';
const QUESTION_DETAIL_URL = compile([QUESTION_LIST_URL, ':id/'].join(''));
const QUESTION_SERVICE_LIST_URL = 'api/questions/list/';

export async function getExerciseQuestions({
  subjectNodeIds,
  shuffleSeed,
  showAnswers = false,
  detail = false,
  active = true,
}) {
  const response = await axios.get(QUESTION_LIST_URL, {
    params: {
      feature_exercise_question: 'True',
      subjectnode: subjectNodeIds,
      active: typeof active === 'boolean' ? capitalize(active.toString()) : undefined,
      all: 'True',
      showAnswers: showAnswers ? 'True' : 'False',
      detail: detail ? 'True' : 'False',
      shuffle_seed: shuffleSeed,
    },
    paramsSerializer: params => qs.stringify(params, { arrayFormat: 'repeat' }),
  });

  return response.data.results;
}

export async function getExerciseQuestionsFromService({
  subjectNodeIds,
  shuffleSeed,
  active,
  showAnswers = false,
}) {
  const queryParams = {
    feature: 'exercise',
    subject_nodes: subjectNodeIds,
    active: typeof active === 'boolean' ? capitalize(active.toString()) : undefined,
    paginate: 'False',
    include_solution: showAnswers,
    shuffle_seed: shuffleSeed,
  };
  // Use generated function after fixing query parameter typing
  const response = await axios.get(QUESTION_SERVICE_LIST_URL, {
    params: queryParams,
    paramsSerializer: params => qs.stringify(params, { arrayFormat: 'repeat' }),
  });
  return response.data;
}

export async function getSectionQuestionsFromService({
  subjectNodeIds,
  showAnswers = false,
  active = true,
  all = true,
  ordering = undefined,
  level = undefined,
  cache = false,
}) {
  const paginate = !all;
  const queryParams = {
    feature: 'section',
    subject_nodes: subjectNodeIds,
    paginate,
    include_solution: showAnswers,
    ordering,
    level,
    active,
  };
  // Use generated function after fixing query parameter typing
  const response = await axios.get(QUESTION_SERVICE_LIST_URL, {
    params: queryParams,
    paramsSerializer: params => qs.stringify(params, { arrayFormat: 'repeat' }),
  });
  return paginate ? response.data.results : response.data;
}

export async function getReflectionQuestionsFromService(subjectNodeId) {
  const params = {
    type: 'LongTextQuestion',
    feature: 'exercise',
    subject_nodes: subjectNodeId,
    ordering: 'created',
    active: 'True',
  };
  const response = await axios.get(QUESTION_SERVICE_LIST_URL, { params });
  return response.data;
}

export async function fetchExamStyleQuestionsByTopic(subjectId, params = {}) {
  const url = SCHOOLSTAFF_API_URLS['subject-exam-style-questions']({ subjectId });
  const response = await axios.get(url, { params });
  return response.data;
}

export async function fetchExamStyleQuestions(subjectId, params = {}) {
  const url = SCHOOLSTAFF_API_URLS['exam-style-questions-list-view']({ subjectId });
  const response = await axios.get(url, { params });
  return response.data;
}

export async function fetchReflectionQuestions(subjectNodeId) {
  const params = {
    type: 'ltq',
    feature_exercise_question: 'True',
    subjectnode: subjectNodeId,
    ordering: 'created',
    active: 'True',
    all: 'True',
    reflection_questions_in_content: 'True',
  };
  const response = await axios.get(QUESTION_LIST_URL, { params });
  return response.data.results;
}

export async function getSectionQuestions(subjectNodeIds, options = {}) {
  const additionalParams = options.additionalParams || {};
  const cache = options.cache || false;
  const params = {
    type: ['stq', 'ftb', 'mcq'],
    feature_section_question: 'True',
    subjectnode: subjectNodeIds,
    all: 'True',
    ...additionalParams,
  };
  const response = await axios.get(QUESTION_LIST_URL, {
    cache,
    params,
    paramsSerializer: p => qs.stringify(p, { arrayFormat: 'repeat' }),
  });

  return params.all === 'True' ? response.data.results : response.data;
}

export async function getQuestion(id, type = null) {
  const response = await axios.get(QUESTION_DETAIL_URL({ id }), { params: { type } });
  return response.data;
}

function getQuestionTypeUrl(questionType, apiType) {
  switch (questionType) {
    case QUESTION_TYPES.MCQ:
      return SCHOOLSTAFF_API_URLS[`mcq-${apiType}`];
    case QUESTION_TYPES.STQ:
      return SCHOOLSTAFF_API_URLS[`stq-${apiType}`];
    case QUESTION_TYPES.LTQ:
      return SCHOOLSTAFF_API_URLS[`ltq-${apiType}`];
    default:
      throw new Error(`Invalid question type: ${questionType}`);
  }
}

export async function createTeacherQuestion(subjectId, data, questionType) {
  const urlFunction = getQuestionTypeUrl(questionType, 'list');
  const url = urlFunction({ subjectId });
  const response = await axios.post(url, data);
  return response.data;
}

export async function editTeacherQuestion(subjectId, questionId, data, questionType) {
  const urlFunction = getQuestionTypeUrl(questionType, 'detail');
  const url = urlFunction({ subjectId, questionId });
  const response = await axios.put(url, data);
  return response.data;
}

export async function deleteTeacherQuestion(subjectId, questionId, questionType) {
  const urlFunction = getQuestionTypeUrl(questionType, 'detail');
  const url = urlFunction({ subjectId, questionId });
  const response = await axios.delete(url);
  return response.data;
}
