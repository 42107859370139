import * as Sentry from '@sentry/vue';

import * as questionsApi from '@apis/questions.js';

export default {
  namespaced: true,
  state: {
    reflectionQuestions: [],
  },
  mutations: {
    setReflectionQuestions(state, reflectionQuestions) {
      state.reflectionQuestions = reflectionQuestions;
    },
  },
  actions: {
    async fetchReflectionQuestions({ commit }, subjectNodeId) {
      try {
        const reflectionQuestions =
          await questionsApi.getReflectionQuestionsFromService(subjectNodeId);
        commit('setReflectionQuestions', reflectionQuestions);
      } catch (error) {
        Sentry.captureMessage(`getReflectionQuestionsFromService: ${error}`);
      }
    },
    clearReflectionQuestions({ commit }) {
      commit('setReflectionQuestions', []);
    },
  },
};
