import keyBy from 'lodash/keyBy.js';

import { ActivitiesService } from '@apis/generated';

export default {
  namespaced: true,
  state: {
    drivingQuestions: [],
  },
  getters: {
    drivingQuestionsById(state) {
      return keyBy(state.drivingQuestions, 'id');
    },
    drivingQuestionsByDqbId(state) {
      return keyBy(state.drivingQuestions, 'driving_question_board');
    },
    getDrivingQuestionByDqbId(state, getters) {
      return dqbId => getters.drivingQuestionsByDqbId[dqbId];
    },
  },
  mutations: {
    setDrivingQuestions(state, drivingQuestions) {
      state.drivingQuestions = drivingQuestions;
    },
  },
  actions: {
    async fetchDrivingQuestions({ commit }, subjectClassId) {
      const drivingQuestionForClassAndBoard =
        await ActivitiesService.activitiesDqbSubjectClassDrivingQuestionsList({ subjectClassId });
      commit('setDrivingQuestions', drivingQuestionForClassAndBoard);
    },
    async createDrivingQuestion(
      { dispatch },
      { subjectClassId, featureId, questionText, externalUrl },
    ) {
      await ActivitiesService.activitiesDqbSubjectClassDrivingQuestionsCreate({
        subjectClassId,
        requestBody: {
          driving_question_board: featureId,
          question_text: questionText,
          external_url: externalUrl,
        },
      });
      await dispatch('fetchDrivingQuestions', subjectClassId);
    },
    async updateDrivingQuestion(
      { dispatch },
      { subjectClassId, featureId, drivingQuestionId, questionText, externalUrl },
    ) {
      await ActivitiesService.activitiesDqbSubjectClassDrivingQuestionsPartialUpdate({
        subjectClassId,
        featureId,
        dqbDrivingQuestionId: drivingQuestionId,
        request_body: {
          questionText,
          externalUrl,
        },
      });
      await dispatch('fetchDrivingQuestions', subjectClassId);
    },
    async uploadDrivingQuestionImages(
      { dispatch },
      { subjectClassId, featureId, drivingQuestionId, images },
    ) {
      await ActivitiesService.activitiesDqbSubjectClassDrivingQuestionsImagesCreate({
        dqbDrivingQuestionId: drivingQuestionId,
        featureId,
        subjectClassId,
        formData: { images },
      });
      await dispatch('fetchDrivingQuestions', subjectClassId);
    },
    async deleteDrivingQuestionImages(
      { dispatch },
      { subjectClassId, featureId, drivingQuestionId, imageIds },
    ) {
      await ActivitiesService.activitiesDqbSubjectClassDrivingQuestionsImagesDestroy({
        dqbDrivingQuestionId: drivingQuestionId,
        featureId,
        subjectClassId,
        idsArray: imageIds,
      });
      await dispatch('fetchDrivingQuestions', subjectClassId);
    },
  },
};
