import { ActivitiesService } from '@apis/generated';

export default {
  createInstance({ featureTitle, subjectNodeId }) {
    return ActivitiesService.activitiesDqbCreate({
      requestBody: {
        title: featureTitle,
        subject_node_id: subjectNodeId,
      },
    });
  },
  removeInstance(featureId) {
    return ActivitiesService.activitiesDqbCreate({ featureId });
  },
  fetchInstance(featureId) {
    return ActivitiesService.activitiesDqbRetrieve({ featureId });
  },
};
