<script>
import { SvgSprite } from 'vue-svg-sprite';

export default {
  name: 'SvgIcon',
  components: {
    SvgSprite,
  },
  props: {
    iconName: {
      type: String,
      required: true,
    },
    size: {
      type: String,
      default: 'm',
      validator: prop => ['s', 'm', 'l', 'xl', 'm-touch', 's-touch', 'custom'].includes(prop),
    },
    customSize: {
      type: String,
      default: '',
    },
  },
  computed: {
    iconSize() {
      if (this.size === 'custom' && !this.customSize) {
        throw new Error('customSize prop is required when size is set to custom');
      }

      const sizeMap = {
        s: '14',
        's-touch': '14',
        m: '20',
        'm-touch': '20',
        l: '28',
        xl: '38',
        custom: this.customSize,
      };
      return sizeMap[this.size];
    },
  },
};
</script>

<template>
  <svg-sprite
    :symbol="iconName"
    :size="iconSize"
    role="presentation"
    url=""
  />
</template>
