import qs from 'qs';

import axios from 'sharedApp/vue-utils/kog-axios.ts';

const apiBaseUrl = '/api/exercise-occasions/';

export async function createExerciseOccasion(subjectNodeId, ltqId, userText, operation) {
  const url = `${apiBaseUrl}subject_nodes/${subjectNodeId}/questions/${ltqId}/ltq_practice_item/`;
  const response = await axios.post(url, {
    user_text: userText,
    operation,
  });
  return response.data;
}

export async function updateExerciseOccasion(
  subjectNodeId,
  ltqId,
  userText,
  operation,
  flaggedByTeacher,
  practiceItemId,
  subjectClassId,
) {
  const url = `${apiBaseUrl}subject_nodes/${subjectNodeId}/questions/${ltqId}/ltq_practice_item/`;
  const response = await axios.put(url, {
    user_text: userText,
    practice_item_id: practiceItemId,
    operation,
    subject_class_id: subjectClassId,
    ...(flaggedByTeacher !== undefined ? { is_flagged_by_teacher: flaggedByTeacher } : {}),
  });
  return response.data;
}

export async function fetchExerciseOccasions(
  subjectNodeId,
  subjectClassId,
  mine,
  all,
  pageSize,
  onlyQuestionsInContent,
) {
  const params = {
    subjectnode: subjectNodeId,
    mine,
    all,
    ...(pageSize ? { page_size: pageSize } : {}),
    ...(subjectClassId ? { subject_class: subjectClassId } : {}),
    reflection_questions_in_content: onlyQuestionsInContent,
  };
  const response = await axios.get(apiBaseUrl, {
    params,
    paramsSerializer: ps => qs.stringify(ps, { arrayFormat: 'repeat' }),
    cache: false,
  });
  return all === 'True' ? response.data.results : response.data;
}
