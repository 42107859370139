const TIMESINCE_CHUNKS = [
  {
    intervalType: 'year',
    duration: 31536000,
  },
  {
    intervalType: 'month',
    duration: 2592000,
  },
  {
    intervalType: 'week',
    duration: 604800,
  },
  {
    intervalType: 'day',
    duration: 86400,
  },
  {
    intervalType: 'hour',
    duration: 3600,
  },
  {
    intervalType: 'minute',
    duration: 60,
  },
  {
    intervalType: 'second',
    duration: 1,
  },
];

const zeroPad2 = val => `0${val}`.slice(-2);

const timeSince = (date, message) => {
  let d = date;
  if (!date) {
    return message;
  }

  if (typeof date !== 'object') {
    d = new Date(date);
  }

  // eslint-disable-next-line unicorn/prefer-date-now
  let seconds = Math.floor((new Date() - d) / 1000);
  let result = '';
  let i;
  for (i = 0; i < TIMESINCE_CHUNKS.length; i += 1) {
    const chunk = TIMESINCE_CHUNKS[i];
    const interval = Math.floor(seconds / chunk.duration);
    if (interval >= 1) {
      result = `${interval} ${chunk.intervalType}${interval > 1 ? 's' : ''}`;
      seconds %= chunk.duration;
      break;
    }
  }
  if (i + 1 < TIMESINCE_CHUNKS.length) {
    const chunk = TIMESINCE_CHUNKS[i + 1];
    const interval = Math.floor(seconds / chunk.duration);
    if (interval >= 1) {
      result += `, ${interval} ${chunk.intervalType}${interval > 1 ? 's' : ''}`;
    }
  }

  return result;
};

const timeAgo = (date, message) => {
  const dateFormated = timeSince(date);
  if (dateFormated === '') {
    return 'Just now';
  }
  if (dateFormated) {
    return `${dateFormated} ago`;
  }
  return message;
};

const dateFormatter = date => {
  const y = date.getFullYear();
  const m = date.getMonth() + 1;
  const d = date.getDate();
  const mm = m < 10 ? `0${m}` : m;
  const dd = d < 10 ? `0${d}` : d;
  return `${y}-${mm}-${dd}`;
};

const humanUnderstandableDateTime = inputDate => {
  const date = new Date(inputDate);
  const localeDate = date.toLocaleDateString('en-GB', {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });
  const localTime = date.toLocaleTimeString('en-GB', {
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
    dayPeriod: 'short',
  });
  return `${localeDate} time ${localTime}`;
};

const humanUnderstandableDateWithWeekday = inputDate => {
  const date = new Date(inputDate);
  const options = {
    weekday: 'long',
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  };
  const timeOptions = {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  };
  const formattedDate = date.toLocaleDateString('en-US', options);
  const formattedTime = date.toLocaleTimeString('en-US', timeOptions).toLowerCase();
  return `${formattedDate}, ${formattedTime}`;
};

const dateStringFormatter = dateString => dateFormatter(new Date(dateString));

const dateTimeFormatter = (dateOrString, delimiter) => {
  const date = new Date(dateOrString);
  const y = date.getFullYear();
  const m = date.getMonth() + 1;
  const d = date.getDate();
  const mm = m < 10 ? `0${m}` : m;
  const dd = d < 10 ? `0${d}` : d;
  const zeroPaddedHours = zeroPad2(date.getHours());
  const zeroPaddedMinutes = zeroPad2(date.getMinutes());
  if (delimiter) {
    return `${y}-${mm}-${dd} ${delimiter} ${zeroPaddedHours}:${zeroPaddedMinutes}`;
  }
  return `${y}-${mm}-${dd} ${zeroPaddedHours}:${zeroPaddedMinutes}`;
};

const prettifyISO8601AsUTC = dateISOString => {
  if (!dateISOString) {
    return null;
  }
  const date = new Date(dateISOString);
  const dateParts = date.toISOString().split(/[T|.]/);
  return `${dateParts[0]} ${dateParts[1]} UTC`;
};

const prettifyISO8601AsUTCDate = dateISOString => {
  if (!dateISOString) {
    return null;
  }
  const dateString = prettifyISO8601AsUTC(dateISOString);
  return dateString.split(' ')[0];
};

const getDateAsDateObject = date => {
  if (date instanceof Date) {
    return date;
  }
  if (typeof date === 'string') {
    return new Date(Date.parse(date));
  }
  throw new Error('Unable to convert the date into a Date object');
};

const getDateTimeStringsPair = date => {
  const zeroPaddedHours = zeroPad2(date.getHours());
  const zeroPaddedMinutes = zeroPad2(date.getMinutes());
  return {
    date: date.toLocaleDateString(),
    time: `${zeroPaddedHours}:${zeroPaddedMinutes}`,
  };
};

const round5 = x => Math.ceil(x / 5) * 5;

const getDateInNearest5Minutes = date => {
  const dateCopy = new Date(date.getTime());
  dateCopy.setMinutes(round5(dateCopy.getMinutes()));
  return dateCopy;
};

const getDateXMinutesLess = (x, date) => {
  const MS_PER_MINUTE = 60000;
  const dateXless = new Date(date.getTime() - x * MS_PER_MINUTE);
  return dateXless;
};

const getDayFromXDaysAgo = x => {
  const thatDay = new Date();
  thatDay.setDate(thatDay.getDate() - x);
  thatDay.setHours(0, 0, 0, 0);
  return thatDay;
};

const getDayFromXMonthsAgo = x => {
  const thatDay = new Date();
  const date = thatDay.getDate();
  thatDay.setMonth(thatDay.getMonth() - x);
  thatDay.setHours(0, 0, 0, 0);
  if (thatDay.getDate() !== date) {
    thatDay.setDate(0);
  }
  return thatDay;
};

const timeGreeting = () => {
  const now = new Date();
  const hour = now.getHours();
  if (hour >= 5 && hour < 12) {
    return 'Good morning';
  }
  if (hour >= 12 && hour < 17) {
    return 'Good afternoon';
  }
  return 'Good evening';
};

const getShortMonthAndDay = date =>
  date.toLocaleDateString('en-US', {
    month: 'short',
    day: 'numeric',
  });

module.exports = {
  timeSince,
  timeAgo,
  dateFormatter,
  dateStringFormatter,
  dateTimeFormatter,
  humanUnderstandableDateTime,
  humanUnderstandableDateWithWeekday,
  prettifyISO8601AsUTC,
  prettifyISO8601AsUTCDate,
  getDateAsDateObject,
  getDateTimeStringsPair,
  getDateInNearest5Minutes,
  getDateXMinutesLess,
  timeGreeting,
  getShortMonthAndDay,
  getDayFromXDaysAgo,
  getDayFromXMonthsAgo,
};
