import {
  deleteAnnotation,
  fetchAnnotations,
  fetchAnnotationsCount,
  updateAnnotation,
} from 'learning/common/api/annotations.js';

export default {
  namespaced: true,
  state: {
    annotationsCount: null,
    selfAnnotations: null,
  },
  mutations: {
    setSelfAnnotations(state, { annotations }) {
      state.selfAnnotations = annotations;
    },
    popAnnotation(state, annotationId) {
      state.selfAnnotations = state.selfAnnotations.filter(
        annotation => annotation.id !== annotationId,
      );
    },
    pushAnnotation(state, newAnnotation) {
      state.selfAnnotations = state.selfAnnotations.map(annotation => {
        if (annotation.id === newAnnotation.id) {
          return newAnnotation;
        }
        return annotation;
      });
    },
    setAnnotationsCount(state, annotationsCount) {
      state.annotationsCount = annotationsCount.reduce((acc, node) => {
        acc[node.subject_node_id] = node.annotations_count;
        return acc;
      }, {});
    },
  },
  actions: {
    async fetchSelfAnnotations({ commit }, { subjectNodeId }) {
      const annotations = await fetchAnnotations(subjectNodeId);
      commit('setSelfAnnotations', { annotations });
    },
    async deleteAnnotation({ commit }, { subjectNodeId, annotationId }) {
      await deleteAnnotation(subjectNodeId, annotationId);
      commit('popAnnotation', annotationId);
    },
    async updateAnnotation({ commit }, { subjectNodeId, annotationId, annotation }) {
      await updateAnnotation(subjectNodeId, annotationId, annotation);
      commit('pushAnnotation', annotation);
    },
    async fetchAnnotationsCount({ commit }, subjectId) {
      const response = await fetchAnnotationsCount(subjectId);
      commit('setAnnotationsCount', response);
    },
  },
};
