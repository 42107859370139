<script>
export default {
  name: 'VerticalExpandTransition',
  props: {
    transitionMode: {
      type: String,
      default: '',
      validator: prop => ['in-out', 'out-in', ''].includes(prop),
    },
  },
  emits: ['end'],
  methods: {
    start(el) {
      // eslint-disable-next-line no-param-reassign
      el.style.height = `${el.scrollHeight}px`;
    },
    end(el) {
      // eslint-disable-next-line no-param-reassign
      el.style.height = '';
      this.$emit('end');
    },
  },
};
</script>

<template>
  <transition
    name="VerticalExpandTransition"
    :mode="transitionMode"
    @enter="start"
    @after-enter="end"
    @before-leave="start"
    @after-leave="end"
  >
    <slot />
  </transition>
</template>

<style scoped>
.VerticalExpandTransition-enter-active,
.VerticalExpandTransition-leave-active {
  will-change: height, opacity, margin-bottom, margin-top, min-height, padding-top, padding-bottom;
  overflow: hidden;
  transition:
    height 0.3s ease,
    margin-bottom 0.3s ease,
    margin-top 0.3s ease,
    min-height 0.3s ease,
    opacity 0.3s ease,
    padding-top 0.3s ease,
    padding-bottom 0.3s ease;
}

.VerticalExpandTransition-enter-from,
.VerticalExpandTransition-leave-to {
  height: 0 !important;
  min-height: 0 !important;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;

  opacity: 0;
}
</style>
