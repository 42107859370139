import type { DjangoSettings } from './django-settings-module.types.ts';

const defaultSettings: Partial<DjangoSettings> = {
  MIXPANEL: {
    AUTH_TOKEN: null,
  },
  CLOUDBEES: {
    AUTH_KEY: null,
  },
  STATIC_OVERRIDES: {
    SHOW_STUDENT_QUESTION_PROGRESS: null,
  },
  SCHOOL_ADMIN_SETTINGS: { SUBSCRIPTION_RENEWAL_TIME_PERIOD_DAYS: 120 },
  PRACTICE_SETTINGS: {
    BATTLE_NUMBER_QUESTIONS: 3,
    BATTLE_SECONDS_PER_QUESTION: 60,
    STRENGTH_TEST_QUESTION_QUANTITY_OPTIONS: [5],
  },
  POSTHOG: {
    API_KEY: null,
  },
  ALLOW_MANUAL_SYNC_WITH_EDLINK: false,
};

const KOG = window.KOG || {};
const settings = KOG.DJANGO_SETTINGS || defaultSettings;

export default settings;
