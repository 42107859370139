<script>
import KogItemList from 'sharedApp/components/base/utils/kog-item-list.vue';

import KogRadio from './kog-radio.vue';

export default {
  name: 'KogRadioGroup',
  components: {
    KogItemList,
    KogRadio,
  },
  props: {
    /**
     * The list of options to show as radio inputs.<br>
     * Structure of an **option** item:<br>
     * - `text: String`<br>
     * - `value: Object | String | Number`<br>
     * - `disabled: Boolean` (optional)<br>
     *
     */
    options: {
      type: Array,
      default: () => [],
    },
    /**
     * The value that is currently selected within the radio-group.
     * It can be used as the write-only alternative to v-model
     */
    selectedValue: {
      type: String,
      required: true,
    },
    /**
     * Applies to all `KogRadio` items.
     */
    isLabelHidden: {
      type: Boolean,
      default: false,
    },
    /**
     * Set this to true if you want the label to appear on the left side of the radio.
     * Applies to all `KogRadio` items.
     */
    isLabelOnLeft: {
      type: Boolean,
      default: false,
    },
    /**
     * Proxy for `kog-item-list`'s prop. If true, the correct radio item will be
     * automatically focused when the component mounts (usually in dropdown usecase)
     */
    autoFocus: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['custom-option-value-change', 'checked', 'keydown', 'update:selectedValue'],
  computed: {
    isAnyRadioChecked() {
      return this.options.some(option => this.isChecked(option));
    },
  },
  methods: {
    isChecked(option) {
      return Boolean(
        this.selectedValue &&
          (this.selectedValue === option.value || this.selectedValue === option.customEditorValue),
      );
    },
    getTabIndex(option, index) {
      if (option.disabled) {
        return null;
      }

      if (!this.isAnyRadioChecked) {
        return index === 0 ? 0 : -1;
      }

      return option.value === this.selectedValue ? 0 : -1;
    },
    onCustomOptionValueChanged(option) {
      /**
       * Emits new value when the custom editor value changes
       *
       * @event custom-option-value-change
       * @type {string}
       */
      this.$emit('custom-option-value-change', option);
    },
    onOptionChecked(value) {
      /**
       * Emits new value in order for it to be approved for being checked
       * by the parent component
       *
       * @event checked
       * @type {string}
       */
      this.$emit('update:selectedValue', value);
      this.$emit('checked', value);
    },
  },
};
</script>

<template>
  <kog-item-list
    ref="root"
    role="radiogroup"
    :auto-focus="autoFocus"
    @keydown="$emit('keydown', $event)"
  >
    <template
      v-for="(option, index) in options"
      :key="`option-${index}`"
    >
      <span
        v-if="option.isGroupTitle"
        class="text-overline KogRadioGroup--groupTitle"
      >
        {{ option.text }}
      </span>
      <kog-radio
        v-else
        class="padd-top-xxs padd-bottom-xxs"
        :value="option.value"
        :label="option.text"
        :is-checked="isChecked(option)"
        :is-disabled="option.disabled"
        :is-label-hidden="isLabelHidden"
        :is-label-on-left="isLabelOnLeft"
        :option="option"
        :tab-index="getTabIndex(option, index)"
        @custom-option-value-change="onCustomOptionValueChanged"
        @checked="onOptionChecked"
      />
    </template>
  </kog-item-list>
</template>
