<script setup lang="ts">
import { computed } from 'vue';

import kogButton from 'sharedApp/components/base/buttons/kog-button.vue';
import { isNgss } from 'sharedApp/services/educationSystem/education-system-service.js';

const HSS_VIDEO_URL = 'https://cdn.jwplayer.com/previews/6ZjWXcNg-CEP86NRF';
const INTERNATIONAL_VIDEO_URL = 'https://cdn.jwplayer.com/previews/kz9dGk9c-CEP86NRF';
const INTERNATIONAL_REONBOARDING_VIDEO_URL = 'https://cdn.jwplayer.com/previews/DgiIn7FV-CEP86NRF';

const props = defineProps<{
  educationSystemName: string;
  nextButtonText: string;
  isReonboarding: boolean;
}>();
defineEmits(['next']);

const videoUrl = computed(() => {
  const internationalVideoUrl = props.isReonboarding
    ? INTERNATIONAL_REONBOARDING_VIDEO_URL
    : INTERNATIONAL_VIDEO_URL;
  return isNgss(props.educationSystemName) ? HSS_VIDEO_URL : internationalVideoUrl;
});
</script>

<template>
  <p class="text-regular">
    The video below walks you through the main areas of the platform and how to use Kognity for
    teaching.</p
  >
  <iframe
    class="OnboardingVideoStep-video"
    :src="videoUrl"
    title="Onboarding video"
    allowfullscreen
  />
  <kog-button
    class="width-full margin-top-m"
    button-style="primary"
    :label="nextButtonText"
    @click="$emit('next')"
  />
</template>

<style scoped>
.OnboardingVideoStep-video {
  aspect-ratio: 16/9;
  width: 100%;
  border: none;
}
</style>
