import qs from 'qs';

import axios from 'sharedApp/vue-utils/kog-axios.ts';

const baseUrl = quizId => `api/activities/quiz/${quizId}/`;
const quizQuestionMappingsUrl = quizId => `${baseUrl(quizId)}questions/`;
const quizQuestionMappingDetailsUrl = (quizId, mappingId) =>
  `${baseUrl(quizId)}questions/${mappingId}/`;
const quizQuestionMappingsOrderUrl = quizId => `${quizQuestionMappingsUrl(quizId)}order/`;
const quizOccasionUrl = quizId => `${baseUrl(quizId)}occasions/`;
const quizOccasionDetailsUrl = (quizId, occasionId) => `${baseUrl(quizId)}occasions/${occasionId}/`;
const quizStudentOccasionListUrl = quizId => `${baseUrl(quizId)}occasions/`;
const quizQuestionResponseUrl = (quizId, occasionId) =>
  `${quizOccasionDetailsUrl(quizId, occasionId)}responses/`;
const quizStudentResponseListUrl = quizId => `${baseUrl(quizId)}occasions/responses/`;
const quizOccasionQuestionSolutionUrl = (quizId, occasionId) =>
  `${baseUrl(quizId)}occasions/${occasionId}/solutions/`;

export async function fetchQuiz(quizId) {
  const response = await axios.get(baseUrl(quizId));
  return response.data;
}

export async function updateQuiz(quizId, quizTitle, quizAttributes) {
  const data = { title: quizTitle, attributes: quizAttributes };
  const response = await axios.patch(baseUrl(quizId), data);
  return response.data;
}

export async function fetchQuizOccasions(quizId, isSubmitted, classId) {
  const params = {
    is_submitted: isSubmitted,
    subject_class_id: classId,
  };
  const response = await axios.get(quizOccasionUrl(quizId), { params });
  return response.data;
}

export async function fetchQuizQuestionMappings(quizId) {
  const response = await axios.get(quizQuestionMappingsUrl(quizId));
  return response.data;
}

export async function createQuizQuestionMapping(mapping) {
  const response = await axios.post(quizQuestionMappingsUrl(mapping.quiz), mapping);
  return response.data;
}

export async function deleteQuizQuestionMapping(quizId, mapping) {
  const response = await axios.delete(quizQuestionMappingDetailsUrl(quizId, mapping.id));
  return response.data;
}

export async function updateQuizQuestionMappingOrder(mappings, quizId) {
  const url = quizQuestionMappingsOrderUrl(quizId);
  const response = await axios.put(url, mappings);
  return response.data;
}

export async function createQuizOccasion(quizId) {
  const url = quizOccasionUrl(quizId);
  const response = await axios.post(url);
  return response.data;
}

export async function updateQuizOccasion(quizId, occasionId, isSubmitted) {
  const data = { is_submitted: isSubmitted };
  const url = quizOccasionDetailsUrl(quizId, occasionId);
  const response = await axios.patch(url, data);
  return response.data;
}

export async function createQuizQuestionResponse(
  quizId,
  occasionId,
  responseType,
  questionUuid,
  userResponse,
) {
  const data = {
    quiz_occasion: occasionId,
    question_uuid: questionUuid,
    response_type: responseType,
    user_response: userResponse,
  };
  const url = quizQuestionResponseUrl(quizId, occasionId);
  const response = await axios.post(url, data);
  return response.data;
}

export async function fetchQuizQuestionResponses(quizId, occasionId, classId) {
  const url = quizQuestionResponseUrl(quizId, occasionId);
  const params = {
    subject_class_id: classId,
  };
  const response = await axios.get(url, { params });
  return response.data;
}

export async function fetchMappableQuestions(subjectNodeIds) {
  const questionsURL = '/api/questions/';
  const response = await axios.get(questionsURL, {
    params: {
      quiz_question: 'True',
      subjectnode: subjectNodeIds,
      active: 'True',
      all: 'True',
    },
    paramsSerializer: params => qs.stringify(params, { arrayFormat: 'repeat' }),
  });

  return response.data;
}

export async function fetchQuestionSolution(quizId, occasionId, subjectClassId, questionUuids) {
  const response = await axios.get(quizOccasionQuestionSolutionUrl(quizId, occasionId), {
    params: {
      question_uuid: questionUuids,
      subject_class_id: subjectClassId,
    },
    paramsSerializer: params => qs.stringify(params, { arrayFormat: 'repeat' }),
  });
  return response.data;
}

export async function fetchQuizOccassionList(quizId, classId = undefined) {
  const response = await axios.get(quizStudentOccasionListUrl(quizId), {
    params: {
      subject_class_id: classId,
    },
  });
  return response.data;
}

export async function fetchQuizResponsesList(quizId, classId = undefined) {
  const response = await axios.get(quizStudentResponseListUrl(quizId), {
    params: {
      subject_class_id: classId,
    },
  });
  return response.data;
}
