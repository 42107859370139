<script>
// eslint-disable-next-line kognity/no-kog-prefix

import KogFontAwesomeIcon from './kog-font-awesome-icon.vue';
import SvgIcon from './svg-icon.vue';

export default {
  name: 'KogIcon',
  components: {
    SvgIcon,
    KogFontAwesomeIcon,
  },
  props: {
    iconClass: {
      type: String,
      required: true,
    },
    faStyle: {
      type: String,
      default: 'solid',
      validator: prop => ['solid', 'regular', 'light'].includes(prop),
    },
    size: {
      type: String,
      default: 'm',
      validator: prop => ['xs', 's', 'm', 'l', 'xl', 'm-touch', 's-touch', 'custom'].includes(prop),
    },
    theme: {
      type: String,
      default: 'dark',
      validator: prop => ['dark', 'light', 'custom'].includes(prop),
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    isNonActionable: {
      type: Boolean,
      default: false,
    },
    isSvgIcon: {
      type: Boolean,
      default: false,
    },
    isImageIcon: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: '',
    },
  },
  computed: {
    iconContainerClass() {
      const iconClasses = [`KogIcon--${this.size}`];

      // To use a custom theme on SVGs, set fill using cascading CSS
      // https://css-tricks.com/cascading-svg-fill-color/
      if (this.theme !== 'custom') {
        iconClasses.push(`KogIcon--${this.theme}`);
      }

      if (this.isDisabled) {
        iconClasses.push('KogIcon--disabled');
      }

      if (this.isNonActionable) {
        iconClasses.push('KogIcon--nonActionable');
      }

      if (this.isImageIcon) {
        iconClasses.push('KogIcon--image');
      }

      return iconClasses;
    },
  },
};
</script>

<template>
  <span
    :class="iconContainerClass"
    :aria-hidden="label === '' ? 'true' : null"
    class="inlineflexContainer flexContainer-center"
  >
    <svg-icon
      v-if="isSvgIcon"
      role="img"
      :aria-label="label"
      :icon-name="iconClass"
      :size="size"
    />
    <img
      v-else-if="isImageIcon"
      :alt="label || 'icon'"
      :src="iconClass"
    />
    <kog-font-awesome-icon
      v-else
      :aria-label="label"
      :fa-class="iconClass"
      :fa-style="faStyle"
      :size="size"
    />
  </span>
</template>

<style scoped>
.KogIcon--light {
  color: var(--kogPlatformWhite);
}

.KogIcon--light > svg {
  fill: var(--kogPlatformWhite);
}

.KogIcon--dark {
  color: var(--kogPlatformGray031);
}

.KogIcon--dark > svg {
  fill: var(--kogPlatformGray031);
}

.KogIcon--dark:hover {
  color: var(--kogPlatformGray009);
}

.KogIcon--dark:hover > svg {
  fill: var(--kogPlatformGray009);
}

.KogIcon--dark.KogIcon--disabled {
  color: var(--kogPlatformGray057);
}

.KogIcon--dark.KogIcon--disabled > svg {
  fill: var(--kogPlatformGray057);
}

.KogIcon--dark.KogIcon--disabled:hover {
  color: var(--kogPlatformGray057);
}

.KogIcon--dark.KogIcon--disabled:hover > svg {
  fill: var(--kogPlatformGray057);
}

.KogIcon--dark.KogIcon--nonActionable:hover {
  color: var(--kogPlatformGray031);
}

.KogIcon--dark.KogIcon--nonActionable:hover > svg {
  fill: var(--kogPlatformGray031);
}

.KogIcon--xs {
  width: 10px;
  min-width: 10px;
  height: 10px;
  min-height: 10px;
}

.KogIcon--s {
  width: 16px;
  min-width: 16px;
  height: 16px;
  min-height: 16px;
}

.KogIcon--m {
  width: 24px;
  min-width: 24px;
  height: 24px;
  min-height: 24px;
}

.KogIcon--s-touch {
  width: 24px;
  min-width: 24px;
  height: 24px;
  min-height: 24px;
}

.KogIcon--m-touch {
  width: 48px;
  min-width: 48px;
  height: 48px;
  min-height: 48px;
}

.KogIcon--l {
  width: 32px;
  min-width: 32px;
  height: 32px;
  min-height: 32px;
}

.KogIcon--xl {
  width: 64px;
  min-width: 64px;
  height: 64px;
  min-height: 64px;
}

.KogIcon--image img {
  width: 100%;
  height: 100%;
}
</style>
