import { groupBy, isEmpty } from 'lodash';

import {
  createExerciseOccasion,
  fetchExerciseOccasions,
  updateExerciseOccasion,
} from 'studyApp/api/exercise-occasions.js';

const namespaced = true;

const getters = {
  getExerciseOccasionByLtqId: state => ltqId =>
    state.exerciseOccasions.find(
      occasion =>
        occasion.practice_items &&
        occasion.practice_items.find(ltqPracticeItem => ltqPracticeItem.question_id === ltqId),
    ),
  getAllOccasionsGroupedByLtqId: state =>
    groupBy(state.exerciseOccasions, occasion => occasion.practice_items[0].question_id),
  getAllOccasionsGroupedBySubjectNode: state =>
    groupBy(state.exerciseOccasions, occasion => occasion.chosen_subjectnodes),
  getAllAnsweredLtqsGroupedByUser: state =>
    state.exerciseOccasions.reduce((answers, occasion) => {
      if (!answers[occasion.user_id]) {
        answers[occasion.user_id] = []; // eslint-disable-line no-param-reassign
      }

      const answeredLtqIds = occasion.practice_items.map(practiceItem => practiceItem.question_id);
      answers[occasion.user_id].push(...answeredLtqIds);
      return answers;
    }, {}),
  getLtqAnswersGroupedByUser: (_state, storeGetters) => ltqId => {
    const occasionsByQuestion = storeGetters.getAllOccasionsGroupedByLtqId;
    if (isEmpty(occasionsByQuestion)) {
      return {};
    }
    const questionOccasions = occasionsByQuestion[ltqId];
    if (isEmpty(questionOccasions)) {
      return {};
    }
    const flattenedAnswers = questionOccasions.map(occasion => ({
      chosenSubjectNodes: occasion.chosen_subjectnodes,
      userId: occasion.user_id,
      userAnswer: occasion.practice_items[0].user_text,
      status: occasion.status,
      doneAt: occasion.done_at,
      flaggedByTeacher: occasion.practice_items[0].is_flagged_by_teacher,
      practiceItemId: occasion.practice_items[0].id,
    }));
    const answersPerUser = groupBy(flattenedAnswers, answer => answer.userId);
    return answersPerUser;
  },
};

const mutations = {
  setExerciseOccasions(state, exerciseOccasions) {
    state.exerciseOccasions = exerciseOccasions;
  },
  setExerciseOccasionsCount(state, exerciseOccasionsPaginated) {
    state.exerciseOccasionsCount = exerciseOccasionsPaginated.count;
  },
};

const actions = {
  async createExerciseOccasion({ commit, state }, { subjectNodeId, ltqId, userText, operation }) {
    const exerciseOccasion = await createExerciseOccasion(
      subjectNodeId,
      ltqId,
      userText,
      operation,
    );
    commit('setExerciseOccasions', [...state.exerciseOccasions, exerciseOccasion]);
    return exerciseOccasion.id;
  },
  async updateExerciseOccasion(
    { commit, state },
    { subjectNodeId, ltqId, userText, operation, flaggedByTeacher, practiceItemId, subjectClassId },
  ) {
    const exerciseOccasion = await updateExerciseOccasion(
      subjectNodeId,
      ltqId,
      userText,
      operation,
      flaggedByTeacher,
      practiceItemId,
      subjectClassId,
    );
    exerciseOccasion.user_id = exerciseOccasion.user;

    const newExerciseOccasions = state.exerciseOccasions.map(occasion => {
      if (occasion.id === exerciseOccasion.id) {
        return exerciseOccasion;
      }
      return occasion;
    });

    commit('setExerciseOccasions', newExerciseOccasions);
    return exerciseOccasion;
  },
  async fetchExerciseOccasions(
    { commit },
    {
      subjectNodeId,
      subjectClassId = null,
      mine = 'True',
      all = 'True',
      pageSize = 0,
      onlyQuestionsInContent = 'True',
    },
  ) {
    commit(
      'setExerciseOccasions',
      await fetchExerciseOccasions(
        subjectNodeId,
        subjectClassId,
        mine,
        all,
        pageSize,
        onlyQuestionsInContent,
      ),
    );
  },
  async fetchExerciseOccasionsCount({ commit }, { subjectNodeId, subjectClassId }) {
    const exerciseOccasions = await fetchExerciseOccasions(
      subjectNodeId,
      subjectClassId,
      'False',
      'False',
      1,
    );
    commit('setExerciseOccasionsCount', exerciseOccasions);
  },
};

const state = {
  exerciseOccasions: [],
  exerciseOccasionsCount: 0,
};

export default {
  namespaced,
  actions,
  getters,
  mutations,
  state,
};
