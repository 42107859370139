/**
 * Directive that links an interactive element to its description in an accessible way.
 * Usage: v-kog-description:[spanId]="descriptionString"
 */
export default {
  mounted(el, binding) {
    const descriptionSpan = document.createElement('span');
    descriptionSpan.setAttribute('id', binding.arg);
    descriptionSpan.classList.add('screenreader-only');
    descriptionSpan.innerHTML = binding.value;
    el.setAttribute('aria-describedby', binding.arg);
    el.parentElement.append(descriptionSpan);

    /**
     * MutationObserver is used for the cases where v-tooltip & kog-description
     * are used on the same element. Both directives use the aria-describedby attribute
     * and when v-tooltip unmounts we need to restore the kog-description one.
     */
    const observer = new MutationObserver(mutationList => {
      mutationList.forEach(mutation => {
        if (mutation.attributeName === 'aria-describedby') {
          const val = el.getAttribute('aria-describedby');

          if (!val) {
            el.setAttribute('aria-describedby', binding.arg);
          }
        }
      });
    });

    observer.observe(el, { attributes: true });

    // eslint-disable-next-line no-param-reassign
    el.KogDescriptionContext = {
      descriptionSpan,
      cleanup() {
        observer.disconnect();
      },
    };
  },
  updated(el, binding) {
    if (binding.value !== binding.oldValue) {
      // eslint-disable-next-line no-param-reassign
      el.KogDescriptionContext.descriptionSpan.innerHTML = binding.value;
    }
  },
  unmounted(el) {
    el.removeAttribute('aria-describedby');
    el.KogDescriptionContext.descriptionSpan.remove();
    el.KogDescriptionContext.cleanup();
  },
};
