<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'KogLoader',
  props: {
    /**
     * Shows whether the loader is visible
     */
    loading: {
      type: Boolean,
      default: false,
    },
    /**
     * Message shown when loader visible
     */
    loadingMsg: {
      type: String,
      default: 'Loading',
    },
    /**
     * Use flex container for the slot content when `loading=false`
     */
    flex: {
      type: Boolean,
      default: false,
    },
  },
});
</script>

<template>
  <div
    class="KogLoader"
    :class="{
      'KogLoader-flex': flex,
      'KogLoader--isLoading': loading,
    }"
  >
    <div
      v-if="loading"
      class="flexContainer flexContainer-column flexContainer-center width-100 padd-m"
    >
      <div class="KogLoader__ballContainer margin-bottom-fine-2">
        <div
          v-for="ball in [1, 2, 3, 4]"
          :key="ball"
          class="KogLoader__ball"
          :class="`KogLoader__ball${ball}`"
          aria-hidden="true"
        />
      </div>
      <div class="screenreader-only"> Please wait </div>
      <div>
        {{ loadingMsg }}
      </div>
    </div>
    <slot v-if="!loading" />
  </div>
</template>

<style scoped>
.KogLoader-flex {
  display: flex;
  flex: 1;
}

.KogLoader__ballContainer {
  width: 16px;
  height: 16px;
}

.KogLoader__ball {
  position: absolute;

  display: inline-block;

  width: 16px;
  height: 16px;
  margin: 1px;

  border-radius: 100%;

  animation-fill-mode: both;
}

.KogLoader__ball1 {
  background-color: var(--kog-colors-green-500);
}

.KogLoader__ball2 {
  background-color: var(--kog-colors-orange-500);
}

.KogLoader__ball3 {
  background-color: var(--kog-colors-yellow-500);
}

.KogLoader__ball4 {
  background-color: var(--kog-colors-pink-500);
}

.KogLoader__ball:nth-child(1) {
  animation: KogLoader__animation 2s 1.5s infinite linear;
}

.KogLoader__ball:nth-child(2) {
  animation: KogLoader__animation 2s 1s infinite linear;
}

.KogLoader__ball:nth-child(3) {
  animation: KogLoader__animation 2s 0.5s infinite linear;
}

.KogLoader__ball:nth-child(4) {
  animation: KogLoader__animation 2s 0s infinite linear;
}

@keyframes KogLoader__animation {
  0% {
    transform: translateX(-300%) scale(0.2);
    opacity: 0;
  }

  20% {
    transform: translateX(-120%);
  }

  50% {
    transform: scale(1);
    opacity: 1;
  }

  80% {
    transform: translateX(120%);
  }

  100% {
    transform: translateX(300%) scale(0.2);
    opacity: 0;
  }
}
</style>
